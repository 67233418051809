import { render, staticRenderFns } from "./ProductSpecial.vue?vue&type=template&id=7a16b1ce&scoped=true"
import script from "./ProductSpecial.vue?vue&type=script&lang=js"
export * from "./ProductSpecial.vue?vue&type=script&lang=js"
import style0 from "./ProductSpecial.vue?vue&type=style&index=0&id=7a16b1ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a16b1ce",
  null
  
)

export default component.exports